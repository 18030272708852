import React, { useEffect, useState } from "react"
import { getWenZhangInfo } from "../../services/wenzhang"
import Layout from "../../components/layout"
import { Helmet } from "react-helmet"

export default ({location, data}) => {

    const [info, setInfo] = useState({})

    useEffect(() => {
        let path = location.search;

        getData(path.split('=')[1])

    }, [location.search])

    const getData = async (uuid) => {
        let data = {
            uuid: uuid,
        }
        let rsp = await getWenZhangInfo(data)
        if (rsp.err === 0) {
            setInfo(rsp.data)
        }
        // let sourceData = data.allInternalPosts.nodes[0].data;
        // sourceData.map()
    }
    return (
        <Layout pageId={"/news"}>
            <Helmet>
                <title>{info.seoTitle}</title>
                <meta name="keywords" content={info.seoKeywords}/>
                <meta name="description" content={info.seoDesc}/>
            </Helmet>
            <div className="container my-3 news-container">
                <div className="news-title">
                    {info.wenzhangBiaoti}
                </div>
                <div className="my-1">
                    <span style={{ color: "#999" }}>{info.fabuRenyuan}</span>
                    <span style={{ color: "#999" }} className='mx-2'>{(info.fabuShijian || '').substr(0, 10)}</span>
                </div>
                <div className='my-3'>
                    <div className="news-body-container" dangerouslySetInnerHTML={{ __html: info.zhuti }}/>
                </div>
            </div>
        </Layout>
    )
}